import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const EmailSMSRepository = ClientRepositoryFactory.get("emailsms");
import jwt from "jsonwebtoken";

export default {
  data() {
    return {
      token: {},
    };
  },
  mounted() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  },
  methods: {
    async sendSMS(message, phoneList, signature) {
      try {
        message = message + `\nRegards\n${signature}\nKotak Treasury`;
        const payload = {
          phones: phoneList,
          text: message,
        };
        await EmailSMSRepository.sendBroadcastSMS(payload);
      } catch (err) {
        this.$swal({
          icon: "error",
          title: "Internal Server Error",
        });
      }
    },
    async sendEmail(message, subject, emailList) {
      try {
        const payload = {
          emails: emailList,
          subject: subject,
          text: message,
        };

        await EmailSMSRepository.sendPlainEmail(payload);
      } catch (err) {
        // alert(err);
        // this.$swal({
        //   icon: "error",
        //   title: "Internal Server Error",
        // });
      }
    },
    async optWA(mobile) {
      const payload = {
        mobile: mobile,
      };

      await EmailSMSRepository.optwa(payload);
    },
    async sendWhatsapp(data) {
      const payload = {
        messages: data,
      };
      console.log(data);
      let datas = await EmailSMSRepository.sendPushWA(payload);
      console.log(datas,"gg");
    },
  },
};
